import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Healthcare`}</p>
    <p>{`ERPNext for Healthcare
ERPNext Healthcare modules helps you manage patients, appointments, consultations and lab tests along with billing and all the other ERPNext goodies`}</p>
    <p>{`Try ERPNext free for 14 days`}</p>
    <p>{`Patient Data
Keep track of all patients, their history and other critical parameters.
Appointments
Maintain a schedule of all your physicians and track appointment bookings for them.
Consultations
Record all observations, assessments and prescriptions of all patient consultations.
Lab Tests
Manage all Lab Tests along with specimens and results and keep them ready for print.
Patient Billing
Track all things financial, including billings and payments with the ERPNext accounts module.
Stores and Inventory
ERPNext stock module will help you manage medical inventory and procurement across warehouses.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      